import React, { useEffect, useState } from "react";
import {
  useChameleonTheme,
  Button,
  Title2,
  Icon,
  Body,
  Callout,
  FlexColumn,
} from "@petsapp/chameleon";
import {
  useStripe,
  useElements,
  Elements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import styled from "styled-components";
import { mdiCheckCircleOutline } from "@mdi/js";

import { useTranslation } from "../../utils/i18n";
import { PaymentRequest } from "../../screens/match/hooks/use-payment-status";
import { Spinner } from "../loading";
import { useCurrency } from "../../hooks/use-currency";
import { stripePromise } from "../../utils/stripe";

const Form = styled.form`
  margin-bottom: 1rem;
`;

const CardWrapper = styled.div`
  padding: 2rem 0;
`;

export const SuccessWrapper = styled(FlexColumn)`
  padding: 1rem;
  align-items: center;
  height: auto;

  h2 {
    margin-bottom: 0;
    margin-top: 1rem;
  }
`;

const PaymentForm = (
  props: PaymentRequest & {
    onPaymentSucceeded?: () => void;
    onSuccessButtonClick(): void;
    successButtonCopy: string;
  },
) => {
  const { t } = useTranslation(["chat"]);
  const stripe = useStripe();
  const elements = useElements();
  const { chameleon } = useChameleonTheme() as Record<string, any>;
  const [status, setStatus] = useState<
    "succeeded" | "processing" | "error" | "idle"
  >("idle");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const { operatorName, paymentPayload } = props;
  const { amount, currency } = paymentPayload.charge;
  const [formLoading, setFormLoading] = useState<boolean>(true);

  const onFormReady = () => {
    setFormLoading(false);
  };

  const { formatted: displayAmount } = useCurrency({
    amount: amount / 100,
    currency,
  });

  const onSubmit = async (e: any) => {
    e.preventDefault();
    setStatus("processing");
    setErrorMessage(null);

    if (stripe && elements) {
      const payment = await stripe.confirmPayment({
        elements,
        redirect: "if_required",
      });

      if (payment.error) {
        setErrorMessage(
          t("chat.payment_failed_specific_error", {
            paymentError: payment.error.message,
          }),
        );
        setStatus("error");
      } else {
        setStatus("succeeded");
      }
    }
  };

  useEffect(() => {
    if (status === "succeeded" && props.onPaymentSucceeded) {
      props.onPaymentSucceeded();
    }
  }, [props, status]);

  if (status === "succeeded") {
    return (
      <SuccessWrapper>
        <Icon
          path={mdiCheckCircleOutline}
          size={68}
          color={chameleon.color.Green}
        />
        <Title2 style={{ marginBottom: "1rem" }}>
          {t("chat.payment_successful")}
        </Title2>
        <Button
          onClick={props.onSuccessButtonClick}
          variant="secondary"
          state="active"
        >
          {props.successButtonCopy}
        </Button>
      </SuccessWrapper>
    );
  }

  return (
    <Form action="" onSubmit={onSubmit}>
      {props.hideTitle ? null : (
        <Title2 marginBottomSize={1}>{t("chat.label_payment_request")}</Title2>
      )}
      <Callout marginBottomSize={1}>
        {t("chat.operator_payment_request", {
          operatorName,
          amount: displayAmount,
        })}
      </Callout>

      <CardWrapper>
        <PaymentElement onReady={onFormReady} />
      </CardWrapper>
      {!formLoading ? (
        <FlexColumn style={{ height: "auto" }}>
          <Button
            type="submit"
            variant="primary"
            state={status === "processing" ? "loading" : "active"}
          >
            {t("chat.make_payment")}
          </Button>
          {status === "error" && (
            <Body
              style={{
                marginTop: "1rem",
                marginBottom: 0,
                color: chameleon.color.Red,
              }}
            >
              {errorMessage}
            </Body>
          )}
        </FlexColumn>
      ) : (
        <Spinner />
      )}
    </Form>
  );
};

export const PaymentView: React.FC<{
  paymentStatus: PaymentRequest;
  onPaymentSucceeded: () => void;
  onSuccessButtonClick(): void;
  hideTitle?: boolean;
  successButtonCopy: string;
}> = ({
  paymentStatus,
  successButtonCopy,
  hideTitle = false,
  onPaymentSucceeded,
  onSuccessButtonClick,
}) => {
  return (
    <Elements
      stripe={stripePromise}
      options={{
        clientSecret:
          paymentStatus.paymentPayload.charge.paymentIntent.client_secret,
      }}
    >
      <PaymentForm
        {...paymentStatus}
        successButtonCopy={successButtonCopy}
        hideTitle={hideTitle}
        onPaymentSucceeded={onPaymentSucceeded}
        onSuccessButtonClick={onSuccessButtonClick}
      />
    </Elements>
  );
};
