import { useApiGetter } from "@petsapp/use-api";
import useSession from "./use-session";

export type DeliveryAddress = {
  customerGivenName: string;
  customerFamilyName: string;
  customerAddressLine1: string;
  customerAddressLine2?: string;
  customerCity: string;
  customerPostcode: string;
  customerMobileNumber?: string;
  customerCountry: string;
};

export function useDeliveryAddress() {
  const { account, hasFeature, pets } = useSession();
  const identityId = pets && pets.length > 0 ? pets[0].identityId : "NONE";

  const deliveryAddressRes = useApiGetter<{
    deliveryAddress: DeliveryAddress | null;
    suggestedFields: Partial<DeliveryAddress>;
    countries: string[];
  }>(`/account/${account?.accountId}/delivery-address`, identityId, 1);

  const medicationDeliveryEnabled = hasFeature({
    feature: "medication_delivery",
    identityId,
  });

  return { ...deliveryAddressRes, medicationDeliveryEnabled };
}
