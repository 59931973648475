import * as Sentry from "@sentry/react";
import { ExtraErrorData as ExtraErrorDataIntegration } from "@sentry/integrations";

if (process.env.REACT_APP_RUN_SENTRY === "true") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_WEB,
    release: process.env.REACT_APP_SENTRY_RELEASE,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    integrations: [new ExtraErrorDataIntegration()],
  });

  Sentry.setTag("project", "client-dashboard");
}

export default Sentry;
