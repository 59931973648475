import { Reducer, useEffect, useMemo, useReducer } from "react";
import { IdentityPet } from "@petsapp/types";
import { useContext, createContext } from "react";

interface IIdentityContext {
  identities: IdentityPet[];
  currentIdentity: IdentityPet;
  updateIdentity: (identity: IdentityPet) => void;
  setCurrentIdentity: (identityId: string) => void;
  getIdentity: (identityId: string) => IdentityPet | undefined;
}

interface IdentityProviderProps {
  initialIdentityId: string;
  identities: IdentityPet[];
}

type State = {
  identities: IdentityPet[];
  currentIdentity?: IdentityPet;
  currentIdentityId: string;
};

type Action<T> = {
  type: T;
};

type ActionWithPayload<T, P> = Action<T> & { payload: P };
type Actions =
  | ActionWithPayload<"set-identities", IdentityPet[]>
  | ActionWithPayload<"set-current-identity", string>
  | ActionWithPayload<"update-identity", IdentityPet>;

const reducer: Reducer<State, Actions> = (state, action) => {
  switch (action.type) {
    case "set-identities":
      return { ...state, identities: action.payload };
    case "set-current-identity": {
      const newIdentity = state.identities.find(
        (identity) => identity.identityId === action.payload,
      );

      if (newIdentity) {
        return { ...state, currentIdentityId: action.payload };
      } else {
        throw new Error("Missing identity");
      }
    }
    case "update-identity": {
      return {
        ...state,
        identities: state.identities.map((identity) => {
          if (identity.identityId === action.payload.identityId) {
            return action.payload;
          }
          return identity;
        }),
      };
    }
  }
};

export const IdentityProvider: React.FC<IdentityProviderProps> = ({
  children,
  identities: _identities,
  initialIdentityId,
}) => {
  const [state, dispatch] = useReducer(reducer, {
    identities: _identities,
    currentIdentity: _identities.find(
      (id) => id.identityId === initialIdentityId,
    ),
    currentIdentityId: initialIdentityId ?? _identities[0].identityId,
  });

  useEffect(() => {
    dispatch({ type: "set-identities", payload: _identities });
  }, [_identities]);

  const currentIdentity = useMemo(() => {
    return state.identities.find(
      (identity) => identity.identityId === state.currentIdentityId,
    ) as IdentityPet; // Maybe not the best to cast here
  }, [state.currentIdentityId, state.identities]);

  const value: IIdentityContext = {
    identities: state.identities,
    currentIdentity,
    updateIdentity: (identity: IdentityPet) => {
      dispatch({ type: "update-identity", payload: identity });
    },
    setCurrentIdentity: (id: string) => {
      dispatch({ type: "set-current-identity", payload: id });
    },
    getIdentity: (identityId: string) => {
      return state.identities.find(
        (identity) => identity.identityId === identityId,
      );
    },
  };

  return (
    <IdentityContext.Provider value={value}>
      {children}
    </IdentityContext.Provider>
  );
};

const IdentityContext = createContext<IIdentityContext>({
  identities: [],
  /**
   * We can cast here as this is the default context value when
   * not used in a provider which we do not allow and throw an
   * error for.
   */
  currentIdentity: {} as IdentityPet,
  updateIdentity() {},
  setCurrentIdentity() {},
  getIdentity() {
    return undefined;
  },
});

export const useIdentityContext = () => {
  const context = useContext(IdentityContext);

  if (context == null) {
    throw new Error(
      "useIdentityContext must be used within an IdentityProvider",
    );
  }

  return context;
};
