import { useEffect, useRef } from "react";
export default function useIsMounted() {
    var isMounted = useRef(true);
    useEffect(function () {
        return function () {
            isMounted.current = false;
        };
    }, []);
    return isMounted;
}
