import { Body, Title1, Button } from "@petsapp/chameleon";
import { useTranslation } from "../utils/i18n";
import styled from "styled-components";

/**
 * Used in the global error view and also within
 * the ScreenLayout for useApiGetter request errors
 */
export const ErrorView = ({
  onRefreshClick,
}: {
  onRefreshClick: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        justifyContent: "center",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        height: "auto",
      }}
    >
      <Title1 style={{ marginBottom: 24 }}>{t("error_generic")}</Title1>
      <Body style={{ marginBottom: 24 }}>{t("refresh_page_instruction")}</Body>
      <Button variant="primary" onClick={onRefreshClick}>
        {t("refresh")}
      </Button>
    </div>
  );
};

const GlobalErrorBoundaryWrapper = styled.div`
  background: ${({ theme }) => theme.chameleon.color.VeryLightGrey};
  display: flex;
  height: 100vh;

  > div {
    max-width: 960px;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
  }
`;

/**
 * Used for top level errors
 */
const GlobalErrorUI = () => {
  return (
    <GlobalErrorBoundaryWrapper>
      <ErrorView
        onRefreshClick={() => {
          window.location.reload();
        }}
      />
    </GlobalErrorBoundaryWrapper>
  );
};

export { GlobalErrorUI };
