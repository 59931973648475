import React from "react";
import { FlexColumn, FlexCenter, Title2 } from "@petsapp/chameleon";
import styled from "styled-components";
import { useTranslation } from "../utils/i18n";

export default function Loading() {
  const { t } = useTranslation();
  return (
    <FlexCenter style={{ height: "100%" }}>
      <FlexColumn
        style={{
          alignItems: "center",
          height: "100%",
          justifyContent: "center",
        }}
      >
        <Title2>{t("loading")}</Title2>
      </FlexColumn>
    </FlexCenter>
  );
}

const SpinnerContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Spinner = () => (
  <SpinnerContainer>
    <StyledSpinner viewBox="0 0 50 50">
      <circle
        className="path"
        cx="25"
        cy="25"
        r="20"
        fill="none"
        strokeWidth="2"
      />
    </StyledSpinner>
  </SpinnerContainer>
);

const StyledSpinner = styled.svg`
  animation: rotate 1s linear infinite;
  margin: 40px;
  width: 50px;
  height: 50px;

  & .path {
    stroke: ${({ theme }) => theme.chameleon.color.Primary};
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;
