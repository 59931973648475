import { Title1, IconButton, FlexRow } from "@petsapp/chameleon";
import { mdiArrowLeft } from "@mdi/js";
import { navigate } from "@reach/router";
import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
import { ErrorView } from "./error";
import { Spinner } from "./loading";
import React, { ReactNode } from "react";

const ScreenWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: ${({ theme }) => theme.chameleon.color.White};

  hr {
    width: 100%;
    border-top: 0px solid ${({ theme }) => theme.chameleon.color.VeryLightGrey};
    height: 0;
  }

  ${breakpoint("tablet")`
    border-radius: 24px;
  `}
`;

const ScreenContentWrapper = styled.div`
  overflow: scroll;
  padding: 0 1rem;
  width: 100%;
  min-height: 0;
  height: 100%;

  ${breakpoint("tablet")`
    padding: 2rem;
  `}
`;

const ScreenHeader = styled.header`
  padding: 1rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${breakpoint("tablet")`
    min-height: 100px;
    padding: 3rem 2rem 2rem;
  `}

  h1 {
    margin-bottom: 0;
  }
`;

const ScreenLayout: React.FC<{
  title: string;
  loading?: boolean;
  back?: string;
  error?: boolean;
  right?: ReactNode;
}> = ({ children, title, loading = false, back, error, right }) => {
  return (
    <ScreenWrapper>
      <ScreenHeader>
        <FlexRow style={{ alignItems: "center" }}>
          {back && (
            <IconButton
              style={{ marginRight: "1rem" }}
              variant="secondary"
              path={mdiArrowLeft}
              onClick={() => back && navigate(back)}
            />
          )}
          <Title1>{title}</Title1>
        </FlexRow>
        {right ?? null}
      </ScreenHeader>
      <Hr />
      {loading && <Spinner />}
      {error && (
        <ErrorWrapper>
          <ErrorView
            onRefreshClick={() => {
              window.location.reload();
            }}
          />
        </ErrorWrapper>
      )}
      {!loading && !error && (
        <ScreenContentWrapper>{children}</ScreenContentWrapper>
      )}
    </ScreenWrapper>
  );
};

const ErrorWrapper = styled.div`
  padding: 0 2rem;
  display: flex;
  flex-grow: 2;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Hr = styled.hr`
  height: 0;
  margin-bottom: 24px;

  ${breakpoint("tablet")`
      margin-bottom: 0;
      `}
`;

export { ScreenLayout };
