import Pusher from "pusher-js";
var pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
    cluster: "eu",
    forceTLS: true,
    activityTimeout: 60000,
    authEndpoint: process.env.REACT_APP_BASE_URL + "/public/token/pusher",
    auth: {
        headers: {
            "x-identity": "NONE",
            "x-metadata": {},
        },
    },
});
export var subscribeToDataTopic = function (topic, _listener) {
    function listener(event, data) {
        if (event.startsWith("pusher:"))
            return;
        _listener(data);
    }
    var channel = pusher.subscribe(topic);
    channel.bind_global(listener);
    return function () {
        channel.unbind();
        pusher.unsubscribe(topic);
    };
};
export var unsubscribeFromAllPusherChannels = function () {
    try {
        pusher.allChannels().forEach(function (channel) {
            pusher.unsubscribe(channel.name);
        });
    }
    catch (e) {
        console.error(e);
    }
};
