import { Callout, Title2, Headline } from "@petsapp/chameleon";
import { useApiGetter } from "@petsapp/use-api";
import React, { useEffect, useState, useCallback } from "react";
import styled from "styled-components";
import { ScreenLayout } from "../../components/screen-layout";
import useI18nDate from "../../hooks/use-i18n-date";
import { useTranslation } from "../../utils/i18n";
import happyDog from "../logged-out-payment-request/assets/happy-dog.png";
import doctorDog from "./assets/doctor-dog.png";
import { useAnalytics } from "@petsapp/use-analytics";

type Appointment = {
  appointmentId: string;
  startTime: string;
  dataOwnerId: string;
  confirmed: string;
  reason?: string;
};

type ErrorCodes =
  | "gula.appointmentConfirmation.appointmentNotFound"
  | "gula.appointmentConfirmation.invalidPayload"
  | "gula.appointmentConfirmation.invalidSourceEntityType"
  | "gula.appointmentConfirmation.missingSourceIds"
  | "gula.appointmentConfirmation.notificationNotFound";

type AppointmentConfirmationSource = "email" | "app" | null;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  text-align: center;
  height: 100%;
  min-width: 50%;
  align-items: center;
  gap: 1rem;
  justify-content: center;
  background-color: #fff;
`;

export const LoggedOutAppointmentConfirmation: React.FC<{}> = () => {
  const { t } = useTranslation(["appointment-confirmation"]);
  const params = new URLSearchParams(window.location.search);
  const notificationId = params.get("nId");
  const source: AppointmentConfirmationSource = params.get(
    "source",
  ) as AppointmentConfirmationSource;

  const { loading, data, error } = useApiGetter<{
    appointments: Appointment[];
  }>(
    `/appointments/confirmation-multiple?nId=${notificationId}`,
    "NONE",
    2,
    false,
  );

  return (
    <ScreenLayout
      loading={loading}
      title={t("appointment_confirmation.header")}
    >
      <Content>
        {error && !loading && (
          <Error
            errorCodes={
              typeof error.responseBody === "object"
                ? error.responseBody.code
                : error.responseBody
            }
          />
        )}
        {data?.appointments && notificationId && !error && !loading && (
          <ConfirmAppointment
            notificationId={notificationId}
            appointments={data.appointments}
            source={source ?? null}
          />
        )}
      </Content>
    </ScreenLayout>
  );
};

const ConfirmAppointment: React.FC<{
  appointments: Appointment[];
  notificationId: string;
  source: AppointmentConfirmationSource;
}> = ({ appointments, notificationId, source }) => {
  const { track } = useAnalytics();
  const { t } = useTranslation(["appointment-confirmation"]);
  const formatDate = useI18nDate({
    day: "numeric",
    month: "long",
    hour: "numeric",
    minute: "numeric",
  });

  const [confirmationMessage, setConfirmationMessage] = useState<string | null>(
    null,
  );

  const handleConfirm = useCallback(async () => {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/appointments/confirmation`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          notificationId,
          appointmentIds: appointments
            .filter((a) => !a.confirmed)
            .map((a) => a.appointmentId),
          dataOwnerId: appointments[0].dataOwnerId,
          source: source,
        }),
      },
    );

    if (!response.ok) {
      setConfirmationMessage(t("appointment_confirmation.error_generic"));
      track(
        {
          view: "appointment-confirmation-web",
          event: "appointment-confirmation-failed",
        },
        {
          type: "appointment-confirmation",
          notificationId: notificationId,
        },
      );
      return;
    }

    if (appointments.length > 1) {
      setConfirmationMessage(t("appointment_confirmation.multiple_confirmed"));
      track(
        {
          view: "appointment-confirmation-web",
          event: "appointment-confirmation-multiple-confirmed",
        },
        {
          type: "appointment-confirmation",
          notificationId: notificationId,
        },
      );
      return;
    }

    setConfirmationMessage(t("appointment_confirmation.single_confirmed"));
    track(
      {
        view: "appointment-confirmation-web",
        event: "appointment-confirmation-confirmed",
      },
      {
        type: "appointment-confirmation",
        notificationId: notificationId,
      },
    );
    return;
    // Only need to worry about appointments changing, ignore the other dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointments]);

  useEffect(() => {
    if (
      appointments &&
      appointments.filter((a) => a.confirmed).length === appointments.length
    ) {
      setConfirmationMessage(t("appointment_confirmation.already_confirmed"));
    } else {
      handleConfirm();
    }
    // Only need to worry about appointments changing, ignore the other dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointments]);

  const getTitle = (appointment: Appointment) => {
    if (appointment.reason) {
      return t("appointment_confirmation.scheduled_with_reason", {
        time: formatDate(appointment.startTime),
        reason: appointment.reason,
      });
    }

    return t("appointment_confirmation.scheduled_without_reason", {
      time: formatDate(appointment.startTime),
    });
  };

  if (appointments.length > 1) {
    return (
      <>
        <img src={doctorDog} alt="" />
        <Title2>
          {t("appointment_confirmation.multiple_appointments_notice")}
        </Title2>
        {appointments.map((appointment) => {
          return (
            <Headline>
              {`${getTitle(appointment)} - ${t("status")}`}:{" "}
              {appointment.confirmed
                ? t("appointment_confirmation.status_confirmed")
                : t("appointment_confirmation.status_unconfirmed")}
            </Headline>
          );
        })}
        {confirmationMessage && <Callout>{confirmationMessage}</Callout>}
      </>
    );
  }

  return (
    <>
      <img src={doctorDog} alt="" />
      <Title2>{getTitle(appointments[0])}</Title2>
      {confirmationMessage && <Callout>{confirmationMessage}</Callout>}
    </>
  );
};

const Error: React.FC<{ errorCodes: ErrorCodes | string }> = ({
  errorCodes,
}) => {
  const { t } = useTranslation(["appointment-confirmation"]);
  const [errorMessage, setErrorMessage] = useState<string>(t("unknown_error"));

  useEffect(() => {
    if (
      errorCodes === "gula.appointmentConfirmation.invalidPayload" ||
      errorCodes === "gula.appointmentConfirmation.invalidSourceEntityType" ||
      errorCodes === "gula.appointmentConfirmation.missingSourceIds" ||
      errorCodes === "gula.appointmentConfirmation.notificationNotFound" ||
      errorCodes === "gula.appointmentConfirmation.appointmentNotFound"
    ) {
      setErrorMessage(t("appointment_confirmation.not_found_error"));
    }
  }, [errorCodes, t]);

  return (
    <>
      <img src={happyDog} alt="" />
      <Title2 marginBottomSize={1}>{errorMessage}</Title2>
      <Callout>{t("appointment_confirmation.contact_clinic_prompt")}</Callout>
    </>
  );
};
