import { mdiCheckCircleOutline } from "@mdi/js";
import {
  ButtonLink,
  Callout,
  FlexColumn,
  Icon,
  Line,
  Title1,
  useChameleonTheme,
} from "@petsapp/chameleon";
import useApi from "@petsapp/use-api";
import { RouteComponentProps } from "@reach/router";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
import { Layout } from "../../components/layout";
import useSession from "../../hooks/use-session";
import { useTranslation } from "../../utils/i18n";
import InfoCard from "./components/info-card";

function getParams() {
  const params = new URLSearchParams(window.location.search);

  return {
    wp: params.get("wp"),
    identityId: params.get("identityId"),
  };
}

// why needed? https://cheatsheetseries.owasp.org/cheatsheets/Unvalidated_Redirects_and_Forwards_Cheat_Sheet.html
function validateMatchGroupId(textToCheck: string) {
  return /^[a-z0-9-~]*$/i.test(textToCheck);
}

function useRedirectLinkFromURL() {
  const [redirectLink, setRedirectLink] = useState("/");

  useEffect(() => {
    const url = new URL(window.location.href);
    const matchGroupId = url.searchParams.get("matchGroupId");
    if (matchGroupId && validateMatchGroupId(matchGroupId)) {
      setRedirectLink(`/chat/${matchGroupId}`);
    }
  }, []);

  return redirectLink;
}

const Container = styled(FlexColumn)`
  ${breakpoint("tablet")`
    border-radius: 24px;
  `}

  padding: 2rem;
  align-content: center;
  justify-content: flex-start;
  background-color: ${({ theme }) => theme.chameleon.color.White};
`;

export default function SubscriptionSuccess({
  onClose,
}: RouteComponentProps & {
  onClose?: () => void;
}) {
  const { chameleon } = useChameleonTheme() as Record<string, any>;
  const { post } = useApi();
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation(["wellness-plans"]);
  const redirectLink = useRedirectLinkFromURL();
  const { resetWellnessPlansInitialParams } = useSession();

  const getButtonState = () => {
    if (loading) {
      return "loading";
    }
    return "active";
  };

  const onDone = () => {
    resetWellnessPlansInitialParams();
    setLoading(true);
    post("/wellness-plans/finish-flow", identityId ?? "NONE", "{}", 2).then(
      () => {
        onClose && onClose();
      },
    );
  };

  const { identityId } = getParams();

  return (
    <Layout>
      <Container>
        <div style={{ marginBottom: "2rem" }}>
          <Icon
            path={mdiCheckCircleOutline}
            color={chameleon.color.Green}
            size={64}
          />
        </div>
        <Title1>{t("wellness_plans.pet_added_to_health_plan_generic")}</Title1>
        <Callout textColor={chameleon.color.Grey} marginBottomSize={3}>
          {t("wellness_plans.benefit_info")}
        </Callout>
        <ButtonLink
          state={getButtonState()}
          style={{ alignSelf: "flex-start" }}
          onClick={onDone}
          href={redirectLink}
          variant="secondary"
        >
          {t("done")}
        </ButtonLink>
        <div style={{ marginTop: "2rem" }}>
          <Line />
          <InfoCard
            title={t("wellness_plans.important_update")}
            description={[
              t("wellness_plans.payment_info_stripe"),
              t("wellness_plans.payment_schedule_info"),
              t("wellness_plans.email_confirmation_info"),
            ]}
          />
        </div>
      </Container>
    </Layout>
  );
}
