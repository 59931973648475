import { useTranslation } from "react-i18next";
import { useCallback, useMemo } from "react";

export default function useI18nDate(opts?: Intl.DateTimeFormatOptions) {
  const { i18n } = useTranslation();
  const { language } = i18n;

  const dateFormatter = useMemo(
    () =>
      new Intl.DateTimeFormat(
        language,
        opts || {
          year: "numeric",
          month: "numeric",
          day: "numeric",
        },
      ),
    [language, opts],
  );

  const formatter = useCallback(
    (dateString: string | Date): string => {
      const date = new Date(dateString);

      if (isNaN(date.getTime())) {
        return dateString as string;
      }

      return dateFormatter.format(date);
    },
    [dateFormatter],
  );

  return formatter;
}
