var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useContext, useEffect, useState } from "react";
import * as Auth0 from "auth0-js";
import { PetsAppAuth, } from "@petsapp/auth-client";
var SCOPE = "openid profile email offline_access";
var WebCredentialsStorage = /** @class */ (function () {
    function WebCredentialsStorage() {
    }
    WebCredentialsStorage.prototype.set = function (credentials) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                window.localStorage.setItem(WebCredentialsStorage.AUTH_STORAGE_KEY, JSON.stringify(credentials));
                return [2 /*return*/, Promise.resolve()];
            });
        });
    };
    WebCredentialsStorage.prototype.get = function () {
        return __awaiter(this, void 0, void 0, function () {
            var maybeRawCredentials, credentials;
            return __generator(this, function (_a) {
                maybeRawCredentials = localStorage.getItem(WebCredentialsStorage.AUTH_STORAGE_KEY);
                if (!maybeRawCredentials) {
                    return [2 /*return*/];
                }
                credentials = JSON.parse(maybeRawCredentials);
                return [2 /*return*/, credentials];
            });
        });
    };
    WebCredentialsStorage.prototype.clear = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                localStorage.removeItem(WebCredentialsStorage.AUTH_STORAGE_KEY);
                return [2 /*return*/, Promise.resolve()];
            });
        });
    };
    WebCredentialsStorage.AUTH_STORAGE_KEY = "auth2";
    return WebCredentialsStorage;
}());
export var getNewAuthClient = function (domain) {
    var debugMode = window.localStorage.getItem("auth2-debug") !== null;
    return new PetsAppAuth({
        storage: new WebCredentialsStorage(),
        baseUrl: domain,
        fetch: function () {
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            return window.fetch.apply(window, args);
        },
        debug: debugMode,
        onExpire: function () {
            var event = new CustomEvent("@petsapp/logout");
            window.dispatchEvent(event);
        },
        appId: "client-dashboard",
        appUrl: window.location.origin,
    });
};
export var onLocationChange = function () { return __awaiter(void 0, void 0, void 0, function () {
    var href, auth;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                href = window.location.href;
                auth = getNewAuthClient(process.env.REACT_APP_AUTH_DOMAIN);
                return [4 /*yield*/, auth.persistCredentialsFromUrl(href)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}); };
export var AuthContext = React.createContext({
    state: "booting",
    loginGoogle: function () { },
    getGoogleLink: function () { return ""; },
    getFacebookLink: function () { return ""; },
    getSSOFeatures: function () { return Promise.reject(); },
    loginFacebook: function () { },
    loginEmail: function () { return Promise.reject(); },
    resetPassword: function () { return Promise.reject(); },
    logout: function () { },
    getToken: function () { return Promise.reject("No token"); },
    register: function () { return Promise.reject("Not setup"); },
});
export var AuthProvider = function (_a) {
    var children = _a.children, domain = _a.domain, newDomain = _a.newDomain, clientID = _a.clientID, audience = _a.audience, redirectUri = _a.redirectUri, appState = _a.appState;
    var config = {
        responseType: "token",
        scope: SCOPE,
        domain: domain,
        clientID: clientID,
        audience: audience,
        redirectUri: redirectUri,
    };
    var client = useState(new Auth0.WebAuth(config))[0];
    var authV2 = useState(getNewAuthClient(newDomain))[0];
    var logout = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, authV2.logout()];
                case 1:
                    _a.sent();
                    client.logout({ returnTo: document.location.origin });
                    return [2 /*return*/];
            }
        });
    }); }, [client, authV2]);
    useEffect(function () {
        var listener = function () {
            logout();
        };
        window.addEventListener("@petsapp/logout", listener);
        return function () { return window.removeEventListener("@petsapp/logout", listener); };
    }, [client, logout]);
    var checkSession = function () {
        return new Promise(function (res, rej) {
            return client.checkSession({}, function (err, data) { return (err ? rej(err) : res(data)); });
        });
    };
    var shouldUseV2Password = useCallback(function (email) { return __awaiter(void 0, void 0, void 0, function () {
        var methods;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, authV2.getMethodsForEmail(email)];
                case 1:
                    methods = _a.sent();
                    return [2 /*return*/, methods.loginMethods.some(function (m) { return m.type === "password" && m.version === 2; })];
            }
        });
    }); }, [authV2]);
    var useGetToken = function () {
        var _a = useState(null), token = _a[0], setToken = _a[1];
        function getToken() {
            return __awaiter(this, void 0, void 0, function () {
                var newToken, data;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, authV2.accessToken({}).catch(function () { return null; })];
                        case 1:
                            newToken = _a.sent();
                            if (newToken) {
                                return [2 /*return*/, newToken];
                            }
                            if (!(token == null || token.expires > Date.now())) return [3 /*break*/, 3];
                            return [4 /*yield*/, checkSession()];
                        case 2:
                            data = _a.sent();
                            setToken({
                                token: data.accessToken,
                                expires: Date.now() + data.expiresIn * 1000,
                            });
                            return [2 /*return*/, data.accessToken];
                        case 3: return [2 /*return*/, token.token];
                    }
                });
            });
        }
        return useCallback(getToken, [setToken, token]);
    };
    var loginSocial = function (connection) {
        return client.authorize({
            scope: SCOPE + "offline_access",
            audience: process.env.REACT_APP_AUTH0_API,
            connection: connection,
            appState: appState,
        });
    };
    var loginEmail = function (_a) {
        var email = _a.email, password = _a.password;
        return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, shouldUseV2Password(email)];
                    case 1:
                        if (!_b.sent()) return [3 /*break*/, 3];
                        return [4 /*yield*/, authV2.login({
                                connection: "password",
                                password: password,
                                username: email,
                            })];
                    case 2:
                        _b.sent();
                        window.location.reload();
                        return [2 /*return*/];
                    case 3: return [2 /*return*/, new Promise(function (resolve, reject) {
                            return client.login({
                                scope: SCOPE + "offline_access",
                                audience: process.env.REACT_APP_AUTH0_API,
                                realm: "Username-Password-Authentication",
                                email: email,
                                password: password,
                                // @ts-ignore
                                appState: appState,
                            }, function (err, data) { return (err ? reject(err) : resolve(data)); });
                        })];
                }
            });
        });
    };
    var resetPassword = function (_a) {
        var email = _a.email;
        return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, shouldUseV2Password(email)];
                    case 1:
                        if (!_b.sent()) return [3 /*break*/, 3];
                        return [4 /*yield*/, authV2.resetPassword({
                                email: email,
                            })];
                    case 2:
                        _b.sent();
                        return [2 /*return*/];
                    case 3: return [2 /*return*/, new Promise(function (resolve, reject) {
                            return client.changePassword({
                                email: email,
                                connection: "Username-Password-Authentication",
                            }, function (err) { return (err ? reject(err) : resolve("")); });
                        })];
                }
            });
        });
    };
    var _b = useState("booting"), state = _b[0], setInternalState = _b[1];
    var _c = useState(), extraData = _c[0], setExtraData = _c[1];
    var getToken = useGetToken();
    var loginGoogle = useCallback(function () { return loginSocial("google-oauth2"); }, []);
    var getGoogleLink = useCallback(function () {
        return authV2.getGoogleLoginUrl();
    }, [authV2]);
    var loginFacebook = useCallback(function () { return loginSocial("facebook"); }, []);
    var getFacebookLink = useCallback(function () {
        return authV2.getFacebookLoginUrl();
    }, [authV2]);
    var getSSOFeatures = useCallback(function () {
        return authV2.getSSOFeatures();
    }, [authV2]);
    useEffect(function () {
        function setup() {
            return __awaiter(this, void 0, void 0, function () {
                var appState_1, error_1, token;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, , 3]);
                            return [4 /*yield*/, new Promise(function (resolve, rej) {
                                    client.parseHash(function (err, res) {
                                        if (err) {
                                            rej(err);
                                        }
                                        resolve((res === null || res === void 0 ? void 0 : res.appState) || {});
                                    });
                                })];
                        case 1:
                            appState_1 = (_a.sent());
                            if (appState_1) {
                                setExtraData(function (s) { return (__assign(__assign({}, s), appState_1)); });
                            }
                            return [3 /*break*/, 3];
                        case 2:
                            error_1 = _a.sent();
                            console.error(error_1);
                            return [3 /*break*/, 3];
                        case 3: return [4 /*yield*/, getToken().catch(function (e) { return null; })];
                        case 4:
                            token = _a.sent();
                            setInternalState(token != null ? "authenticated" : "unauthenticated");
                            return [2 /*return*/];
                    }
                });
            });
        }
        setup();
        // I only want this to run once on boot
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    function register(params) {
        return __awaiter(this, void 0, void 0, function () {
            var firstName, lastName;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        firstName = params.firstName.trim();
                        lastName = params.lastName.trim();
                        return [4 /*yield*/, shouldUseV2Password()];
                    case 1:
                        if (!_a.sent()) return [3 /*break*/, 3];
                        return [4 /*yield*/, authV2.signUp({
                                password: params.password,
                                email: params.email,
                                firstName: firstName,
                                lastName: lastName,
                            })];
                    case 2:
                        _a.sent();
                        window.location.reload();
                        return [2 /*return*/];
                    case 3: return [4 /*yield*/, new Promise(function (resolve, reject) {
                            client.signup({
                                connection: "Username-Password-Authentication",
                                email: params.email,
                                password: params.password,
                                // @ts-ignore
                                given_name: firstName,
                                family_name: lastName,
                                name: firstName + " " + lastName,
                            }, function (err, data) {
                                if (err) {
                                    reject(err);
                                }
                                else {
                                    resolve(data);
                                }
                            });
                        })];
                    case 4:
                        _a.sent();
                        return [2 /*return*/, loginEmail({ email: params.email, password: params.password })];
                }
            });
        });
    }
    return (_jsx(AuthContext.Provider, __assign({ value: {
            state: state,
            extraData: extraData,
            loginGoogle: loginGoogle,
            loginFacebook: loginFacebook,
            loginEmail: loginEmail,
            logout: logout,
            resetPassword: resetPassword,
            getToken: getToken,
            register: register,
            getGoogleLink: getGoogleLink,
            getFacebookLink: getFacebookLink,
            getSSOFeatures: getSSOFeatures,
        } }, { children: children }), void 0));
};
var useAuth = function () {
    var auth = useContext(AuthContext);
    if (!auth) {
        throw new Error("useAuth can only be used in a AuthProdiver");
    }
    return auth;
};
export default useAuth;
