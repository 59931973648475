var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx } from "react/jsx-runtime";
import useSWR from "swr";
import useAuth from "@petsapp/use-auth";
import React, { useState, useContext, useCallback, useEffect } from "react";
import { debounce } from "lodash";
import join from "url-join";
import useIsMounted from "./use-is-mounted";
import { subscribeToDataTopic } from "./pusher";
var ApiContext = React.createContext({
    get: function () {
        return Promise.reject();
    },
    patch: function () {
        return Promise.reject();
    },
    post: function () {
        return Promise.reject();
    },
    put: function () {
        return Promise.reject();
    },
    makePath: function () {
        return "";
    },
});
export var ApiProvider = function (_a) {
    var domainMap = _a.domainMap, children = _a.children;
    var auth = useAuth();
    var makePath = useCallback(function (path, version) {
        var basePath = domainMap[version];
        return join(basePath, path);
    }, [domainMap]);
    var get = useCallback(function (path, identityId, version, headers, authHeader) {
        if (version === void 0) { version = 1; }
        if (headers === void 0) { headers = {}; }
        if (authHeader === void 0) { authHeader = true; }
        return __awaiter(void 0, void 0, void 0, function () {
            var url, _headers, token;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = makePath(path, version);
                        _headers = __assign({ "X-Identity": identityId, "X-Metadata": "{}" }, headers);
                        if (!authHeader) return [3 /*break*/, 2];
                        return [4 /*yield*/, auth.getToken()];
                    case 1:
                        token = _a.sent();
                        _headers.Authorization = "Bearer " + token;
                        _a.label = 2;
                    case 2: return [2 /*return*/, fetch(url, {
                            headers: _headers,
                        })];
                }
            });
        });
    }, [makePath]);
    var post = useCallback(function (path, identityId, body, version, headers, authHeader) {
        if (version === void 0) { version = 1; }
        if (headers === void 0) { headers = {}; }
        if (authHeader === void 0) { authHeader = true; }
        return __awaiter(void 0, void 0, void 0, function () {
            var url, _headers, token;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = makePath(path, version);
                        _headers = __assign({ "X-Identity": identityId, "X-Metadata": "{}" }, headers);
                        if (!authHeader) return [3 /*break*/, 2];
                        return [4 /*yield*/, auth.getToken()];
                    case 1:
                        token = _a.sent();
                        _headers.Authorization = "Bearer " + token;
                        _a.label = 2;
                    case 2: return [2 /*return*/, fetch(url, {
                            method: "POST",
                            body: body,
                            headers: _headers,
                        })];
                }
            });
        });
    }, [makePath]);
    var patch = useCallback(function (path, identityId, body, version, headers, authHeader) {
        if (version === void 0) { version = 1; }
        if (headers === void 0) { headers = {}; }
        if (authHeader === void 0) { authHeader = true; }
        return __awaiter(void 0, void 0, void 0, function () {
            var url, _headers, token;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = makePath(path, version);
                        _headers = __assign({ "X-Identity": identityId, "X-Metadata": "{}" }, headers);
                        if (!authHeader) return [3 /*break*/, 2];
                        return [4 /*yield*/, auth.getToken()];
                    case 1:
                        token = _a.sent();
                        _headers.Authorization = "Bearer " + token;
                        _a.label = 2;
                    case 2: return [2 /*return*/, fetch(url, {
                            method: "PATCH",
                            body: body,
                            headers: _headers,
                        })];
                }
            });
        });
    }, [makePath]);
    var put = useCallback(function (path, identityId, body, version, headers) {
        if (version === void 0) { version = 1; }
        if (headers === void 0) { headers = {}; }
        return __awaiter(void 0, void 0, void 0, function () {
            var url, token;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = makePath(path, version);
                        return [4 /*yield*/, auth.getToken()];
                    case 1:
                        token = _a.sent();
                        return [2 /*return*/, fetch(url, {
                                method: "PUT",
                                body: body,
                                headers: __assign({ "X-Identity": identityId, "X-Metadata": "{}", Authorization: "Bearer " + token }, headers),
                            })];
                }
            });
        });
    }, [makePath]);
    return (_jsx(ApiContext.Provider, __assign({ value: { makePath: makePath, get: get, patch: patch, post: post, put: put } }, { children: children }), void 0));
};
var useApi = function () { return useContext(ApiContext); };
var ApiRequestError = /** @class */ (function (_super) {
    __extends(ApiRequestError, _super);
    function ApiRequestError(_a) {
        var json = _a.json, response = _a.response, url = _a.url;
        var _this = _super.call(this, "Error fetching api") || this;
        _this.name = "ApiRequestError";
        _this.responseBody = json;
        _this.statusCode = response.status;
        _this.url = url;
        return _this;
    }
    return ApiRequestError;
}(Error));
export { ApiRequestError };
function _json(res, url, opts) {
    return __awaiter(this, void 0, void 0, function () {
        var json, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, res.json()];
                case 1:
                    json = _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    return [3 /*break*/, 3];
                case 3:
                    if (res.ok)
                        return [2 /*return*/, json];
                    console.log("Req opts:", opts);
                    console.log("Res url:", url);
                    console.log("Res status:", res.status);
                    console.log("Res json:", json);
                    throw new ApiRequestError({
                        json: json,
                        response: res,
                        url: url,
                    });
            }
        });
    });
}
export var useApiGetter = function (path, identityId, version, authHeader) {
    if (version === void 0) { version = 1; }
    if (authHeader === void 0) { authHeader = true; }
    var isMounted = useIsMounted();
    var get = useApi().get;
    var _a = useState(), topic = _a[0], setTopic = _a[1];
    var _b = useSWR([path, identityId, version], function () { return __awaiter(void 0, void 0, void 0, function () {
        var res, out, contentType, topicForUpdates;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, get(path, identityId == null ? "NONE" : identityId, version, {}, authHeader)];
                case 1:
                    res = _a.sent();
                    contentType = res.headers.get("content-type");
                    if (!contentType) return [3 /*break*/, 7];
                    if (!(contentType.indexOf("application/json") > -1)) return [3 /*break*/, 3];
                    return [4 /*yield*/, _json(res, path)];
                case 2:
                    out = _a.sent();
                    return [3 /*break*/, 7];
                case 3:
                    if (!(contentType === "application/pdf")) return [3 /*break*/, 5];
                    return [4 /*yield*/, res.blob()];
                case 4:
                    out = _a.sent();
                    return [3 /*break*/, 7];
                case 5: return [4 /*yield*/, res.text()];
                case 6:
                    out = _a.sent();
                    _a.label = 7;
                case 7:
                    if (isMounted) {
                        topicForUpdates = res.headers.get("X-Topic-For-Updates");
                        setTopic(topicForUpdates);
                    }
                    return [2 /*return*/, out];
            }
        });
    }); }, {
        revalidateOnFocus: true,
        revalidateOnReconnect: true,
        shouldRetryOnError: true,
    }), data = _b.data, error = _b.error, revalidate = _b.revalidate;
    useEffect(function () {
        if (topic) {
            var handleRevalidateTrigger = debounce(function () {
                if (isMounted.current && revalidate) {
                    revalidate();
                }
            }, 150);
            return subscribeToDataTopic(topic, handleRevalidateTrigger);
        }
    }, [topic, isMounted, revalidate]);
    return {
        data: data,
        loading: data === undefined && error === undefined,
        error: error,
        refresh: revalidate,
    };
};
export default useApi;
