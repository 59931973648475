import { mdiCheck } from "@mdi/js";
import {
  Body,
  Icon,
  Title1,
  Title2,
  ButtonLink,
  Callout,
  Button,
  useChameleonTheme,
} from "@petsapp/chameleon";
import { isMobile } from "react-device-detect";
import { Action } from "@petsapp/types";
import useApi, { useApiGetter } from "@petsapp/use-api";
import React, { useMemo, useEffect, useState } from "react";
import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
import { Layout } from "../../components/layout";
import { ScreenLayout } from "../../components/screen-layout";
import { PaymentView } from "../../components/payment/stand-alone-view";
import happyDog from "./assets/happy-dog.png";
import group from "./assets/screen-group.png";
import { Doodle } from "./assets";
import useSession from "../../hooks/use-session";
import Sentry from "../../utils/sentry";
import { useNavigate } from "@reach/router";
import { useTranslation } from "../../utils/i18n";

type PaymentIntent = {
  id: string;
  client_secret: string;
};

type Charge = {
  amount: number;
  currency: string;
  paymentIntent: PaymentIntent;
};

type PaymentPayload = {
  charge: Charge;
  paymentIsCancelled: boolean;
  paymentIsComplete: boolean;
};

type MatchPayment = {
  paymentPayload: PaymentPayload;
  matchId: string;
  operatorName: string;
  country: string;
};

const UpsellImageContainer = styled.div`
  width: 100%;
  ${breakpoint("tablet")`
    display: none;
  `}

  img {
    object-fit: contain;
    width: 100%;
    display: block;

    ${breakpoint("tablet")`
    border-radius: 24px;
  `}
  }
`;

const UpsellContainer = styled.div`
  background: #f89056;
  display: grid;
  max-height: 100%;
  grid-template-rows: 1fr min-content;

  ${breakpoint("tablet")`
    border-radius: 24px;
  `}

  img {
    width: 100%;
  }
`;

const UpsellContentConatiner = styled.div`
  padding: 2rem 1rem;
  width: fit-content;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${breakpoint("tablet")`
    padding: 3rem 4rem 2rem;
  `}
`;

const useGetAction = (action?: Action) => {
  const [loadingAction, setLoadingAction] = useState(false);
  const [enrichedAction, setEnrichedAction] = useState<Record<
    string,
    any
  > | null>(null);

  const { get } = useApi();
  useEffect(() => {
    async function getAction(actionId: string) {
      setLoadingAction(true);
      try {
        const enrichedAction = await get(
          `/public/action/${actionId}`,
          "NONE",
          1,
          {},
          false,
        );

        setEnrichedAction(await enrichedAction.json());
      } catch (error) {
        Sentry.captureException(error);
      } finally {
        setLoadingAction(false);
      }
    }

    if (action != null) {
      getAction(action.actionId);
    }
  }, [action, get]);

  return {
    enrichedAction: enrichedAction,
    loading: loadingAction,
  };
};

const Upsell: React.FC<{
  enrichedAction: Record<string, any> | null;
  onNextClick(): void;
}> = ({ onNextClick, enrichedAction }) => {
  const { chameleon } = useChameleonTheme();
  const { t } = useTranslation(["logged-out-invite"]);

  const textColor = chameleon.color.White;
  const { pets = [] } = enrichedAction || {};

  let patientName = pets?.length > 0 ? pets[0].patientName : null;

  const title = patientName
    ? t("logged_out_invite.why_dont_you_sign_up", {
        patientName: patientName,
      })
    : t("logged_out_invite.sign_up_to_petsapp");

  return (
    <UpsellContainer>
      <UpsellContentConatiner>
        <Doodle style={{ marginLeft: "-2rem", display: "block" }} />
        <Title1 textColor={textColor}>{title}</Title1>
        <Callout textColor={textColor}>
          {t("logged_out_invite.with_the_app_you_can")}
        </Callout>
        <ul>
          {[
            t("logged_out_invite.access_all_chats"),
            t("logged_out_invite.share_photos_videos_files"),
            t("logged_out_invite.manage_pets_information"),
            t("logged_out_invite.request_manage_appointments"),
            t("logged_out_invite.receive_notifications"),
          ].map((benefit) => {
            return (
              <li
                key={benefit}
                style={{
                  display: "grid",
                  margin: "1rem 0",
                  gap: "0.5rem",
                  gridTemplateColumns: "40px 1fr",
                  alignItems: "center",
                }}
              >
                <Icon
                  path={mdiCheck}
                  color={chameleon.color.Primary}
                  size={34}
                />
                <div>
                  <Body marginBottomSize={0} style={{ color: textColor }}>
                    {benefit}
                  </Body>
                </div>
              </li>
            );
          })}
        </ul>
        {isMobile && enrichedAction ? (
          <ButtonLink
            state="active"
            variant="primary"
            width="fit-content"
            href={enrichedAction.shortLink}
          >
            {t("logged_out_invite.download_the_app")}
          </ButtonLink>
        ) : (
          <Button
            state="active"
            variant="primary"
            width="fit-content"
            onClick={onNextClick}
          >
            {t("logged_out_invite.download_the_app")}
          </Button>
        )}
      </UpsellContentConatiner>
      <UpsellImageContainer>
        <img src={group} alt="" />
      </UpsellImageContainer>
    </UpsellContainer>
  );
};

const NoPayment: React.FC<{}> = () => {
  const { t } = useTranslation(["logged-out-payment-request"]);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        height: "100%",
        alignItems: "center",
        gap: "1rem",
        justifyContent: "center",
      }}
    >
      <img src={happyDog} alt="" />
      <Title2 marginBottomSize={1}>
        {t("logged_out_payment_request.payment_request_not_found")}
      </Title2>
      <Callout>{t("logged_out_payment_request.still_need_to_pay")}</Callout>
    </div>
  );
};

const Payment: React.FC<{
  payment: MatchPayment;
  onPaymentSucceeded(): void;
  onSuccessButtonClick(): void;
  successButtonCopy: string;
}> = ({
  payment,
  onPaymentSucceeded,
  onSuccessButtonClick,
  successButtonCopy,
}) => {
  return (
    <PaymentView
      paymentStatus={{
        ...payment,
        shouldShow: true,
        hideTitle: true,
        timeToHide: Infinity,
      }}
      successButtonCopy={successButtonCopy}
      onSuccessButtonClick={onSuccessButtonClick}
      onPaymentSucceeded={onPaymentSucceeded}
    />
  );
};

export const LoggedOutPaymentRequest: React.FC<{}> = () => {
  const session = useSession();
  const { t } = useTranslation(["logged-out-payment-request"]);
  const { matchGroup } = useMemo(() => {
    const params = new URLSearchParams(window.location.search);

    return {
      matchGroup: params.get("matchGroup"),
      matchId: params.get("matchId"),
    };
  }, []);

  const [paymentSuccess, setPaymentSuccess] = useState<boolean>(false);

  const { data, loading, error } = useApiGetter<{
    payments: MatchPayment[];
    action: Action;
  }>(`/checkout/match/${matchGroup}`, "NONE", 2, false);

  const navigate = useNavigate();

  const onPaymentSucceeded = () => {
    setPaymentSuccess(true);
  };

  const { enrichedAction, loading: enrichedActionLoading } = useGetAction(
    data?.action,
  );

  const activePayment = data?.payments.find(
    ({ paymentPayload }) =>
      paymentPayload.paymentIsCancelled === false &&
      paymentPayload.paymentIsComplete === false,
  );

  const onSuccessButtonClick = () => {
    if (enrichedAction && isMobile) {
      window.open(enrichedAction.shortLink);
    } else {
      navigate(
        `/${enrichedAction ? `?actionId=${enrichedAction.actionId}` : ""}`,
      );
      session.refresh();
    }
  };

  const missingValidPayment = !loading && !activePayment;
  const showUpsell = paymentSuccess || missingValidPayment;

  return (
    <>
      <Layout sidebarHideAccount mainContentMaxWidth="100%">
        <InnerLayout singleColumn={!showUpsell}>
          <ScreenLayout
            title={t("logged_out_payment_request.payment_request")}
            loading={loading || enrichedActionLoading}
            error={!!error}
          >
            {activePayment ? (
              <Payment
                payment={activePayment}
                onPaymentSucceeded={onPaymentSucceeded}
                onSuccessButtonClick={onSuccessButtonClick}
                successButtonCopy={
                  isMobile
                    ? t("logged_out_payment_request.download_petsapp")
                    : t("logged_out_payment_request.sign_up_to_petsapp")
                }
              />
            ) : (
              <NoPayment />
            )}
          </ScreenLayout>
          {showUpsell ? (
            <Upsell
              enrichedAction={enrichedAction}
              onNextClick={onSuccessButtonClick}
            />
          ) : null}
        </InnerLayout>
      </Layout>
    </>
  );
};

const InnerLayout = styled.div<{ singleColumn: boolean }>`
  display: grid;
  grid-template-columns: 1fr;
  height: 100%;

  ${breakpoint("mobile", "tablet")`
    height: auto;
  `}

  ${breakpoint("tablet")`
    gap: 2rem;
    margin: 0 2rem;
    justify-content: center;
    grid-template-columns:${
      // @ts-ignore
      ({ singleColumn }) => {
        return singleColumn
          ? `minmax(0, 450px)`
          : `minmax(50%, 1fr) minmax(50%, 1fr)`;
      }
    };
  `}
`;
