var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import retry from "async-retry";
export default function DebouncingBatchQueue(_a) {
    var _b = _a.timeout, timeout = _b === void 0 ? -1 : _b, _c = _a.maxBatchSize, maxBatchSize = _c === void 0 ? -1 : _c, processFn = _a.processFn;
    var timer;
    var queue = [];
    function drainQueue() {
        var clone = __spreadArrays(queue);
        queue = [];
        retry(function () { return processFn(clone); }, { retries: 2 }).catch(function () { });
    }
    return function (item) {
        clearTimeout(timer);
        queue.push(item);
        if ((maxBatchSize < 0 && timeout < 0) ||
            (maxBatchSize >= 0 && queue.length >= maxBatchSize)) {
            drainQueue();
        }
        else if (timeout >= 0) {
            timer = setTimeout(function () {
                drainQueue();
            }, timeout);
        }
    };
}
