import React, { Suspense } from "react";
import styled from "styled-components";
import { Sidebar } from "./sidebar";
import breakpoint from "styled-components-breakpoint";
import { Spinner } from "./loading";

export const Container = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: ${({ theme }) => theme.chameleon.color.VeryLightGrey};
  display: flex;
  flex-direction: column;
  gap: 1rem;

  ${breakpoint("tablet")`
    flex-direction: row;
  `}
`;

interface MainProps {
  readonly maxWidth: string;
}

const Main = styled.div<MainProps>`
  width: 100%;
  height: 100%;
  overflow: auto;

  ${breakpoint("tablet")`
    ${(p) => {
      // @ts-ignore
      let maxWidth = p.maxWidth;

      if (maxWidth) {
        return `max-width: ${maxWidth};`;
      }

      return null;
    }}
    height: calc(100% - 4rem);
    margin: 2rem auto;
  `}
`;

export const Layout: React.FC<{
  mainContentMaxWidth?: string;
  sidebarHideAccount?: boolean;
}> = ({
  children,
  mainContentMaxWidth = "450px",
  sidebarHideAccount = false,
}) => {
  return (
    <Container>
      <Sidebar sidebarHideAccount={sidebarHideAccount} />
      <Main maxWidth={mainContentMaxWidth}>
        <Suspense fallback={<Spinner />}>{children}</Suspense>
      </Main>
    </Container>
  );
};
