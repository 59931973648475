var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useCallback } from "react";
export function hasPermission() {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, "Notification" in window && Notification.permission === "granted"];
        });
    });
}
function urlBase64ToUint8Array(base64String) {
    var padding = "=".repeat((4 - (base64String.length % 4)) % 4);
    var base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");
    var rawData = window.atob(base64);
    var outputArray = new Uint8Array(rawData.length);
    for (var i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}
function install() {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, navigator.serviceWorker.register(process.env.PUBLIC_URL + "/service-worker.js")];
        });
    });
}
function registerForNotifications() {
    return __awaiter(this, void 0, void 0, function () {
        var reg, vapidPublicKey, convertedVapidKey, subscription, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 5, , 6]);
                    return [4 /*yield*/, navigator.serviceWorker.getRegistration(process.env.PUBLIC_URL + "/service-worker.js")];
                case 1:
                    reg = _a.sent();
                    if ((reg === null || reg === void 0 ? void 0 : reg.pushManager) == null) {
                        return [2 /*return*/];
                    }
                    vapidPublicKey = "BLbttnPtfey_X8PMww6D5ooItg_Kwm18pNNvJCisLWWP8ETv_YOjCckHGD7SsDh7n5P4vBgn7IAOMyBD3rJ2TGA";
                    convertedVapidKey = urlBase64ToUint8Array(vapidPublicKey);
                    return [4 /*yield*/, reg.pushManager.getSubscription()];
                case 2:
                    subscription = _a.sent();
                    if (!!subscription) return [3 /*break*/, 4];
                    return [4 /*yield*/, reg.pushManager.subscribe({
                            userVisibleOnly: true,
                            applicationServerKey: convertedVapidKey,
                        })];
                case 3:
                    subscription = _a.sent();
                    _a.label = 4;
                case 4: return [2 /*return*/, {
                        web: { subscription: subscription },
                    }];
                case 5:
                    e_1 = _a.sent();
                    console.error(e_1);
                    return [3 /*break*/, 6];
                case 6: return [2 /*return*/];
            }
        });
    });
}
export default function usePushNotification() {
    return {
        hasPermission: useCallback(function () { return "Notification" in window && Notification.permission === "granted"; }, []),
        hasCapability: useCallback(function () {
            return "serviceWorker" in navigator &&
                "PushManager" in window &&
                "Notification" in window;
        }, []),
        register: registerForNotifications,
        install: install,
    };
}
