import { Callback } from "i18next";
import * as yup from "yup";

/**
 * Initializes the yup locale with the translations using the
 * i18next callback param to set the translations once the
 * initial namespaces have been loaded.
 */
export const initYupLocale: Callback = (_, t) => {
  /**
   * Sets the validation message for general validation errors where
   * we dont define a custom error message.
   *
   * Full list here: https://github.com/jquense/yup/blob/41b9c582575b351123fe51adb6a377dbaad26623/src/locale.ts
   *
   */
  yup.setLocale({
    mixed: {
      required: t("validation:validation.generic_required"),
      oneOf: t("validation:validation.generic_one_of"),
    },
    string: {
      min: ({ min, path }) =>
        t("validation:validation.string_min", {
          min,
          path,
        }),
      max: ({ max, path }) =>
        t("validation:validation.string_max", {
          max,
          path,
        }),
      matches: ({ path }) =>
        t("validation:validation.string_matches", {
          path,
        }),
      email: ({ path }) =>
        t("validation:validation.string_email", {
          path,
        }),
      url: ({ path }) =>
        t("validation:validation.string_url", {
          path,
        }),
    },
    number: {
      min: ({ min, path }) =>
        t("validation:validation.number_min", {
          min,
          path,
        }),
      max: ({ max, path }) =>
        t("validation:validation.number_max", {
          max,
          path,
        }),
      lessThan: ({ less, path }) =>
        t("validation:validation.number_less_than", {
          less,
          path,
        }),
      moreThan: ({ more, path }) =>
        t("validation:validation.number_more_than", {
          more,
          path,
        }),
      positive: ({ path }) =>
        t("validation:validation.number_positive", {
          path,
        }),
      negative: ({ path }) =>
        t("validation:validation.number_negative", {
          path,
        }),
      integer: ({ path }) =>
        t("validation:validation.number_integer", {
          path,
        }),
    },
  });
};
