import React from "react";
import styled from "styled-components";
import { Callout, IconButton } from "@petsapp/chameleon";
import { ReactComponent as L } from "./logo.svg";
import breakpoint from "styled-components-breakpoint";
import { mdiAccount } from "@mdi/js";
import { navigate } from "@reach/router";
import { useTranslation } from "../utils/i18n";
import useSession from "../hooks/use-session";

const Container = styled.div`
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${breakpoint("tablet")`
    flex-direction: column;
    padding: 2rem 0 2rem 2rem;
    height: 100vh;

    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  `}
`;

const Logo = styled(L)`
  width: 50px;
`;

const SupportContainer = styled(Callout)`
  display: none;
  text-decoration: none;

  ${breakpoint("tablet")`
      display: block;
      margin-bottom: 1rem;
   `}
`;

export const Sidebar: React.FC<{ sidebarHideAccount?: boolean }> = ({
  sidebarHideAccount,
}) => {
  const { t } = useTranslation();
  const { isInWellnessPlanFlow } = useSession();
  return (
    <Container>
      <Logo
        onClick={() => {
          navigate("/");
        }}
      />
      {sidebarHideAccount ? null : (
        <div>
          <SupportContainer as="a" href="mailto:support@petsapp.com">
            {t("support")}
          </SupportContainer>
          {!isInWellnessPlanFlow && (
            <IconButton
              onClick={() => navigate("/account")}
              path={mdiAccount}
              variant="primary"
            />
          )}
        </div>
      )}
    </Container>
  );
};
