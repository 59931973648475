var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { v4 as uuid } from "uuid";
import DebouncingBatchQueue from "./debounce-batch-queue";
var metaData = new Map();
var baseUrl;
export var setBaseUrl = function (base) {
    baseUrl = base;
};
function processBatch(queue) {
    if (process.env.NODE_ENV !== "production") {
        console.debug("process: ", queue.map(function (_a) {
            var event = _a.event, data = _a.data;
            return [event.event, data, data.time];
        }));
    }
    var url = baseUrl !== null && baseUrl !== void 0 ? baseUrl : process.env.REACT_APP_BASE_URL;
    return fetch(url + "/analytics", {
        method: "post",
        body: JSON.stringify(queue),
    });
}
var batch = DebouncingBatchQueue({
    timeout: 2500,
    maxBatchSize: 5,
    processFn: processBatch,
});
export function setProperties(properties) {
    Object.keys(properties).forEach(function (key) {
        setProperty(key, properties[key]);
    });
}
export function setProperty(property, value) {
    metaData.set(property, value);
}
export function resetAnalyticsMeta(deviceId) {
    /**
     * Resets the deviceId to a new uuid, this is so
     * we can reliably merge events when user is logged out:
     *  - deviceId: uuid()
     *  - userId: null
     *
     * i.e. we have a many-to-one in relationship in terms of deviceId to userId
     *
     */
    setProperties({ deviceId: deviceId, userId: null });
}
export function setSystemMetaData(systemMetaData) {
    setProperties(systemMetaData);
}
export var setProminentIdentityType = setProperty.bind(null, "prominentIdentityType");
export var setDeviceId = setProperty.bind(null, "deviceId");
export var setUserId = setProperty.bind(null, "userId");
export function trackEvent(event, data) {
    if (data === void 0) { data = {}; }
    if (process.env.NODE_ENV !== "production") {
        console.debug("event: " + event.view + "_" + event.event + "_app = " + JSON.stringify(data));
    }
    var analyticsMetaData = {};
    metaData.forEach(function (v, k) {
        analyticsMetaData[k] = v;
    });
    batch({
        event: {
            view: event.view,
            event: event.event,
            source: "app",
        },
        data: __assign(__assign(__assign({}, data), analyticsMetaData), { insertId: uuid(), time: Date.now() }),
    });
}
function wrap(fn, event, data) {
    return function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        trackEvent(event, data);
        return fn.apply(void 0, args);
    };
}
export function useAnalytics() {
    return {
        track: trackEvent,
        wrap: wrap,
    };
}
export * from "./format-match";
