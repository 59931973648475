import i18n from "i18next";
import {
  initReactI18next,
  useTranslation as _useTranslation,
} from "react-i18next";
import Backend from "i18next-chained-backend";
import XHR from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import LocalStorageBackend from "i18next-localstorage-backend";
import { initYupLocale } from "./init-yup-locale";

const IS_IN_TEST = process.env.JEST_WORKER_ID != null;

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(
    {
      ...(!IS_IN_TEST && {
        backend: {
          backends: [LocalStorageBackend, XHR],
          backendOptions: [
            {},
            {
              loadPath: `${process.env.REACT_APP_CDN_URL}/t/{{lng}}/{{ns}}.json`,
              addPath: `${process.env.REACT_APP_BASE_URL}/t/{{lng}}/{{ns}}`,
            },
          ],
        },
      }),
      ns: ["common-lokalise", "login", "countries", "validation"],
      defaultNS: "common-lokalise",
      fallbackNS: ["common-lokalise", "login"],
      fallbackLng: "en",
      lowerCaseLng: true,
      // don't use keys in the form "messages.welcome.title"
      keySeparator: false,
      interpolation: {
        escapeValue: false,
      },
      compatibilityJSON: "v3",
      debug: process.env.DEBUG_I18N === "true",
      // Useful to set to true for local development, easy to spot missing keys.
      appendNamespaceToMissingKey: process.env.IS_DEV === "true",
    },
    initYupLocale,
  );

/**
 * This is a proxy of the i18next useTranslation function
 * due to a combination of the i18next types along with
 * our Chameleon typography components and what type of
 * children they accept.
 *
 * N.B. use this over the useTranslation package exported from react-i18next
 */
export function useTranslation(namespace?: string[], opts?: any) {
  const out = _useTranslation(namespace, opts);

  return {
    ...out,
    t: (key: string, opts?: any) => out.t<string, string>(key, opts),
  };
}

export default i18n;
