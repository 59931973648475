import { useTranslation } from "../utils/i18n";

type UseCurrency = {
  opts?: Exclude<Intl.NumberFormatOptions, "currency">;
  currency: string;
  amount: number;
};

export const useCurrency = ({ currency, amount, opts }: UseCurrency) => {
  const { i18n } = useTranslation();

  const formatter = Intl.NumberFormat(i18n.language, {
    style: "currency",
    currency,
    ...opts,
  });

  return {
    formatted: formatter.format(amount),
    formatter,
  };
};
