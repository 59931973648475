import {
  setBaseUrl,
  setProminentIdentityType,
  setProperty,
  setSystemMetaData,
  setDeviceId,
  setUserId,
  resetAnalyticsMeta,
} from "@petsapp/use-analytics";
import { version } from "../../package.json";
import { v4 as uuid } from "uuid";
import { detect } from "detect-browser";
setBaseUrl(process.env.REACT_BASE_URL as string);

const deviceIdKey = "pa-user-id";

export function resetDeviceId() {
  const newDeviceId = uuid();
  window.localStorage.setItem(deviceIdKey, newDeviceId);

  resetAnalyticsMeta(newDeviceId);
}

export function resetUserId() {
  setUserId(null);
}

function setUpDeviceId() {
  let userId = window.localStorage.getItem(deviceIdKey);

  if (userId == null) {
    const newDeviceId = uuid();
    userId = newDeviceId;
    localStorage.setItem(deviceIdKey, newDeviceId);
  }

  setDeviceId(userId);
}

setUpDeviceId();

setProminentIdentityType("pet");

setProperty("product", "client-dashboard");

const browser = detect();

setSystemMetaData({
  platform: {
    os: browser?.os || "n/a",
    version: "web",
  },
  app: {
    isDev: process.env.REACT_APP_IS_DEV === "true",
    appVersion: version,
    buildVersion: version,
    bundleIdentifier: "web",
    deviceBrand: "web",
    deviceId: "web",
  },
});
