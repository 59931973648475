export var formatMatchForAnalytics = function (match) {
    var _a, _b, _c;
    if (!match) {
        return;
    }
    return {
        createdAt: match.createdAt,
        interactionType: match.interactionType,
        matchGroup: match.matchGroup,
        operator: match.operator,
        payment: {
            paymentPayload: match.paymentPayload,
            paymentIsComplete: match.paymentIsComplete,
            paymentIsCancelled: match.paymentIsCancelled,
        },
        petClinicId: (_a = match.pet) === null || _a === void 0 ? void 0 : _a.clinicId,
        petGroupId: (_b = match.pet) === null || _b === void 0 ? void 0 : _b.groupId,
        petIdentityId: (_c = match.pet) === null || _c === void 0 ? void 0 : _c.identityId,
        schedule: match.schedule,
        source: match.source,
        state: match.state,
    };
};
