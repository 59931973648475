import {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Input, Button, SelectInput } from "@petsapp/chameleon";
import { ScreenLayout } from "../../components/screen-layout";
import { Layout } from "../../components/layout";
import { RouteComponentProps } from "@reach/router";
import {
  DeliveryAddress,
  useDeliveryAddress,
} from "../../hooks/use-delivery-address";
import useSession from "../../hooks/use-session";
import useApi from "@petsapp/use-api";
import { useTranslation } from "../../utils/i18n";
import { omitBy } from "lodash";

interface Props extends RouteComponentProps {
  onSuccess?: () => void;
}

export function DeliveryAddressForm({ onSuccess }: Props) {
  const { t } = useTranslation(["medication-delivery"]);
  const { loading, data } = useDeliveryAddress();

  const { account, pets } = useSession();
  const { put } = useApi();

  const [form, setForm] = useState<DeliveryAddress>({
    customerGivenName: "",
    customerFamilyName: "",
    customerAddressLine1: "",
    customerAddressLine2: undefined,
    customerCity: "",
    customerPostcode: "",
    customerMobileNumber: undefined,
    customerCountry: "United Kingdom",
  });
  const [errors, setErrors] = useState<Partial<
    Record<keyof DeliveryAddress, string[]>
  > | null>(null);
  const [mode, setMode] = useState<
    "intiializing" | "idle" | "submitting" | "success"
  >("intiializing");

  useEffect(() => {
    if (mode === "intiializing" && !loading) {
      if (data?.deliveryAddress != null) {
        setForm(data.deliveryAddress);
      } else {
        setForm({
          ...form,
          ...data?.suggestedFields,
        });
      }
      setMode("idle");
    }
  }, [data, loading, form, mode]);

  const handleSubmit = useCallback(
    async (ev: FormEvent | MouseEvent) => {
      ev.preventDefault();

      if (!account || !pets || pets.length === 0) {
        return;
      }

      setErrors(null);
      setMode("submitting");

      const res = await put(
        `/account/${account.accountId}/delivery-address`,
        pets[0].identityId,
        JSON.stringify(omitBy(form, (v) => v == null || v.length === 0)),
      );
      const json = await res.json();

      if (res.status === 200) {
        setMode("success");
        onSuccess && onSuccess();
      } else {
        setMode("idle");

        if (json?.error) {
          setErrors(json.error);
        }
      }
    },
    [form, account, pets, put, onSuccess],
  );

  const handleChange = useCallback(
    (propName: keyof DeliveryAddress) => (event: ChangeEvent<any>) => {
      setForm((prev) => ({ ...prev, [propName]: event.target.value }));
    },
    [setForm],
  );

  return (
    <Layout>
      <ScreenLayout
        title={t("medication_delivery.deliver_medication_title")}
        loading={mode === "intiializing"}
        error={false}
      >
        <form onSubmit={handleSubmit}>
          <Input
            label={t("name")}
            id="name"
            value={form.customerGivenName}
            onChange={handleChange("customerGivenName")}
            error={errors?.customerGivenName?.join(". ")}
          />

          <Input
            label={t("lastName")}
            id="family-name"
            value={form.customerFamilyName}
            onChange={handleChange("customerFamilyName")}
            error={errors?.customerFamilyName?.join(". ")}
          />

          <Input
            label={t("medication_delivery.mobile_number")}
            id="mobile-number"
            value={form.customerMobileNumber}
            onChange={handleChange("customerMobileNumber")}
            error={errors?.customerMobileNumber?.join(". ")}
          />

          <SelectInput
            id="delivery-country-select"
            value={form.customerCountry}
            onChange={handleChange("customerCountry")}
            label={t("country")}
            error={errors?.customerCountry?.join(". ")}
          >
            {data?.countries.map((c) => (
              <option key={c} value={c}>
                {c}
              </option>
            ))}
          </SelectInput>

          <Input
            label={t("medication_delivery.address_line1")}
            id="address-line-1"
            value={form.customerAddressLine1}
            onChange={handleChange("customerAddressLine1")}
            error={errors?.customerAddressLine1?.join(". ")}
          />

          <Input
            label={t("medication_delivery.address_line2_optional")}
            id="address-line-2"
            value={form.customerAddressLine2}
            onChange={handleChange("customerAddressLine2")}
            error={errors?.customerAddressLine2?.join(". ")}
          />

          <Input
            label={t("postcode")}
            id="postcode"
            value={form.customerPostcode}
            onChange={handleChange("customerPostcode")}
            error={errors?.customerPostcode?.join(". ")}
          />

          <Input
            label={t("city")}
            id="city"
            value={form.customerCity}
            onChange={handleChange("customerCity")}
            error={errors?.customerCity?.join(". ")}
          />

          <Button
            onClick={handleSubmit}
            state={mode === "submitting" ? "loading" : "active"}
            variant="primary"
          >
            {mode === "success" ? t("saved") : t("save")}
          </Button>
        </form>
      </ScreenLayout>
    </Layout>
  );
}
