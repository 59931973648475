import { Body, Headline, useChameleonTheme } from "@petsapp/chameleon";

export default function InfoCard({
  title,
  description,
}: {
  title: string;
  description: Array<string>;
}) {
  // @ts-ignore
  const { chameleon } = useChameleonTheme();
  return (
    <div>
      <Headline marginBottomSize={2} color={chameleon.color.Grey}>
        {title}
      </Headline>
      {description.map((text) => {
        return (
          <Body
            marginBottomSize={2}
            style={{ color: `${chameleon.color.DarkGrey}` }}
          >
            {text}
          </Body>
        );
      })}
    </div>
  );
}
